.App {
  text-align: center;

  min-height: 100vh;
  color: #1c3664;
  font-family: 'Fira Code';
}
html {
  background-color: #d5eff8;
  width: 100vw;
  
}

.App-link {
  color: #61dafb;
}

nav {
  /* max-width: 80vw; */
  margin: auto;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax( 1fr);
  align-items: center;
  /* width: 75vw; */
}

nav a {

  margin: 20px 0;
  text-decoration: none;
  font-weight: 300;
  font-size: 3vmin;
}

h1 {
  font-weight: 900;
  margin-top: 20vh;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 8vmin;
  /* text-shadow: 1px 1px 10px white; */
  color: #d5eff8;
  opacity: 0;
}

.bird-ship {
  width: 75vw;
}

.kambam {
  width: 100vw;
  margin-top: 10px;
}

.moon{
  /* position: absolute; */
  width: 80vw;
  margin-top: 50px;
  /* left: 0; */
  /* margin-left: ; */
}

.clouds {
  display: inline-block;
  animation: Move-across infinite 30s linear;
  right: -50%;
  width: 100%;
}
.clouds2 {
  display: inline-block;
  animation: Move-across 30s infinite 8s linear;
  right: 0%;
  width: 100%;
}
.clouds-container {
  position: absolute;
  display: inline-block;
  right: -50%;
  width: 200%;
  overflow: hidden;
  height: 100vh;
  z-index: -4;
  animation: Move-across3 infinite 20s linear;
}
.clouds-container2 {
  position: absolute;
  display: inline-block;
  right: 50%;
  width: 200%;
  overflow: hidden;
  height: 100vh;
  z-index: -4;
  animation: Move-across4 infinite 20s linear;
}
.movement {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
}
.intro {
  z-index: 0;
  padding-bottom: 5vh;
}

.background{
  background-image: url('/src/images/dreamland.jpg');
  background-size: cover;
  height: 1200px;
}

.blob1{
  position:absolute;
  top:10vh;
  left:5vw;
  width: 60vw;
  z-index: -10;
}

.blob2{
  position:absolute;
  width: 50vw;
  z-index: -10;
  right: 40px;
  top:25vh;
}

h2 {
  margin-top: 0;
  font-weight: 200;
  letter-spacing: 1.2vmin;
  font-size: 3vmin;
}
@media (min-width: 700px) {
  .blob1{
  position:absolute;
  width: 35vw;
  z-index: -10;
}

.blob2{
  position:absolute;
  width: 50vw;
  z-index: -10;
  right: 100px;
  top:25vh;
}
  .bird-ship {
    width: 25vw;
  }
  .clouds {
    display: inline-block;
    animation: Move-across infinite 30s linear;
    right: -50%;
    width: 50%;
  }

  .clouds-container {
    position: absolute;
    display: inline-block;
    right: -100%;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    z-index: -4;
    animation: Move-across infinite 20s linear;
  }
  .clouds-container2 {
    position: absolute;
    display: inline-block;
    right: 0%;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    z-index: -4;
    animation: Move-across2 infinite 20s linear;
  }

  .movement {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
  }
  h1 {
    font-weight: 900;
    margin-top: 10vh;
    margin-bottom: 0;
    font-size: 9vmin;
    font-family: 'bagel FAT one'
  }
  h2 {
    margin-top: 0;
    font-weight: 200;
    letter-spacing: 1.2vmin;
    font-size: 3vmin;
  }
  .kambam {
    width: 60vw;
    margin-top: 150px;
    margin-bottom: -100px;
    /* background-color: white; */
  }

  nav {
    max-width: 700px;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax( 1fr);
    align-items: center;
    width: 75vw;
  }
  
  nav a {
  
    margin: 40px 0;
    text-decoration: none;
    font-weight: 500;
  
  }

  .moon{
    /* position: absolute; */
    width: 80vw;
    /* left: 0; */
    /* margin-left: ; */
  }
  @keyframes Move-across {
    from {
      right: -50%;
    }
    to {
      right: 0%;
    }
  }
  @keyframes Move-across2 {
    from {
      right: 0%;
    }
    to {
      right: 50%;
    }
  }
}

@keyframes Move-across3 {
  from {
    right: -200%;
  }
  to {
    right: 0%;
  }
}
@keyframes Move-across4 {
  from {
    right: 0%;
  }
  to {
    right: 200%;
  }
}

.card {
  background-color: white;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
  border: 3px solid #1c3664;
  border-radius: 4px;
  box-shadow: 10px 10px #1c3664;
  text-align: left;
}
